.offer-slider-item img {
    /* -webkit-transform: scale(1); */
    /* transform: scale(1); */
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    width: 100%;
  }
  .offer-slider-item:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    overflow: hidden;
    width:100%;
  }
  .offer-slider-item span {
    position: absolute;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    color: var(--white);
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 2px 5px;
    /* background-color: rgba(93, 93, 93, 0.566); */
  }
  .offer-slider-item:hover span {
    opacity: 1;
  }
  