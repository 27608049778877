.css-w4fqad-MuiButtonBase-root-MuiListItemButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: transparent !important;
}

.admin-sideBar-user a {
  color:  var(--white);
  text-decoration: none;
}

.admin-dash-sub-menu span {
  font-size: 14px !important;
}

.admin-dash-sub-menu .active {
  color: #222222;
  background-color: var(--white);
  text-decoration: none;
  width: 80%;
  border-radius: 0 5px 5px 0;
}

.admin-dash-sub-menu {
  margin-bottom: 10px;
}
