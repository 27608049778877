.login-box {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #e5e5f7 10px
    ),
    repeating-linear-gradient(#f6f6ff55, #f6f6ff);
}

.bg-images{
  background-image: url("../../images/Kingdom.jpg");
  width: 100%;
  height: 100vh;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;

}
